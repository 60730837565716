.grid_pattern{
  
  display: grid;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-columns: 1fr 1fr;

}

.App-Section{

  padding: 30px;
}

.App-Container{

  max-width: 800px;
  margin-left: auto;
  margin-right:auto 
}


.app-note {
  background-color: pink;
  margin-bottom: 16px;
  padding: 12px;
}

.app-edit-note {
  background-color: white;
  border: 4px solid black;
  width: 250px;
  padding: 12px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99;
}

.forminput{

  width: 100%;
  padding: 8px;
  font-size:16px;
  box-sizing: border-box;
  border: 1px solid grey;
}